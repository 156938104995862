<template>
  <div>
    <b-card class="open-ticket">
      <b-row>
        <b-col cols="12" lg="10">
          <span
            >If you wish to get help repairing any warning or danger level
            issues please contact support. Charges may apply if you do not pay
            or receive free premium hours from us.</span
          >
        </b-col>
        <b-col cols="12" lg="2" class="d-flex justify-content-end">
          <b-button variant="primary" @click="renderedUrl">
            <span>Open Ticket</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="match-height d-flex justify-content-between">
      <b-col lg="6" md="6" sm="6">
        <b-card class="main-card">
          <h4 class="mb-0">Last Scan Results</h4>
          <b-row>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-success number-size"
                >
                  {{
                    statisticsReportData.last_scan_results
                      ? statisticsReportData["last_scan_results"]["good"]
                      : 0
                  }}
                </h3>

                <h5 class="d-flex justify-content-center mt-1 mb-0">Good</h5>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-warning number-size"
                >
                  {{
                    statisticsReportData.last_scan_results
                      ? statisticsReportData["last_scan_results"]["warning"]
                      : 0
                  }}
                </h3>
                <h5 class="d-flex justify-content-center mt-1 mb-0">Warning</h5>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-danger number-size"
                >
                  {{
                    statisticsReportData.last_scan_results
                      ? statisticsReportData["last_scan_results"]["danger"]
                      : 0
                  }}
                </h3>
                <h5 class="d-flex justify-content-center mt-1 mb-0">Danger</h5>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="6" md="6" sm="6">
        <b-card class="main-card">
          <h4 class="mb-0">Statistics (Last 30 Days)</h4>
          <b-row>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-warning number-size"
                >
                  {{
                    statisticsReportData.statistics
                      ? statisticsReportData["statistics"]["scan_initiated"]
                      : 0
                  }}
                </h3>
                <h5 class="d-flex justify-content-center mt-1 mb-0">
                  Scans Initiated
                </h5>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-success number-size"
                >
                  {{
                    statisticsReportData.statistics
                      ? statisticsReportData["statistics"]["scan_completed"]
                      : 0
                  }}
                </h3>
                <h5 class="d-flex justify-content-center mt-1 mb-0">
                  Scan Completed
                </h5>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4" md="4" sm="6" xs="12">
              <b-card class="card-style">
                <h3
                  class="d-flex justify-content-center feather-danger number-size"
                >
                  {{
                    statisticsReportData.statistics
                      ? statisticsReportData["statistics"]["scan_failed"]
                      : 0
                  }}
                </h3>
                <h5 class="d-flex justify-content-center mt-1 mb-0">
                  Scans Failed
                </h5>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-row class="mb-2">
        <b-col>
          <b-button
            class="float-right"
            variant="primary"
            @click="addPermission"
          >
            <span class="text-nowrap"> Run a Security Scan</span>
          </b-button>
        </b-col>

        <b-modal
          ref="add-permissions"
          no-close-on-backdrop
          hide-footer
          size="lg"
          title="Run A Security Scan"
          scrollable
        >
          <div class="d-block">
            <b-progress :value="progress" variant="primary"></b-progress>
            <b-card v-if="current_step == 1" class="card-style">
              <b-card-text class="height-secutity-scan">
                <b-row class="d-flex justify-content-center">
                  <b-col cols="12" lg="10">
                    <b-row>
                      <b-col class="mb-2">
                        Please select the project you would like to run the
                        security scan against.
                      </b-col>
                    </b-row>

                    <b-row class="d-flex justify-content-center">
                      <b-col
                        cols="12"
                        lg="8"
                        v-if="
                          userData.role_name == 'GLOBAL_ADMIN' ||
                          userData.role_name == 'ACCOUNT_MANAGER'
                        "
                      >
                        <v-select
                          v-model="selectedOrganization"
                          :options="organizationOptions"
                          placeholder="Organization"
                          label="name"
                          @input="selectBillingIdFromOrganization"
                          class="select-organization"
                        >
                          <span slot="no-options"> {{ noOptionMessage }} </span>
                        </v-select>
                      </b-col>

                      <b-col cols="12" lg="8" class="mt-2">
                        <v-select
                          v-model="selectedProject"
                          :options="projectOptions"
                          placeholder="All Projects"
                          :disabled="projectOptions.length == 0"
                          class="select-project"
                        />
                      </b-col>

                      <div
                        v-if="
                          userData.role_name == 'GLOBAL_ADMIN' ||
                          userData.role_name == 'ACCOUNT_MANAGER'
                        "
                        class="loader"
                      >
                        <b-img
                          src="../../../../public/loading.svg"
                          height="22px"
                          width="50px"
                          alt="loading"
                          class="loading-project"
                          v-if="
                            selectedOrganization !== null &&
                            projectLoading &&
                            theme == 'light'
                          "
                        />
                        <b-img
                          src="../../../../public/darkLoading.svg"
                          height="22px"
                          width="50px"
                          alt="darkLoading"
                          class="loading-project"
                          v-if="
                            selectedOrganization !== null &&
                            projectLoading &&
                            theme == 'dark'
                          "
                        />
                      </div>
                      <div
                        v-if="
                          userData.role_name !== 'GLOBAL_ADMIN' &&
                          userData.role_name !== 'ACCOUNT_MANAGER'
                        "
                        class="loader mt-0"
                      >
                        <b-img
                          src="../../../../public/loading.svg"
                          height="22px"
                          width="50px"
                          alt="loading"
                          class="loading-project"
                          v-if="projectLoading && theme == 'light'"
                        />
                        <b-img
                          src="../../../../public/darkLoading.svg"
                          height="22px"
                          width="50px"
                          alt="darkLoading"
                          class="loading-project"
                          v-if="projectLoading && theme == 'dark'"
                        />
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-button
                class="float-right"
                variant="primary"
                @click="onClickNext"
                :disabled="selectedProject == ''"
                >Next</b-button
              >
            </b-card>

            <b-card v-if="current_step == 2" class="card-style">
              <b-card-text class="height-secutity-scan">
                In order for Carbon to run a security scan against the project
                <b>"{{ this.selectedProject.split(" (")[0] }}"</b> , access must
                be granted to the following service account: <br /><br /><b
                  >"{{ securityAnalysisEmailId }}"</b
                ><br /><br />
                The following roles will be required:

                <ul>
                  <li>
                    <b>Viewer</b>
                  </li>
                  <li>
                    <b>Security Reviewer</b>
                  </li>
                  <li>
                    <b>Stackdriver Account Viewer</b>
                  </li>
                </ul>
                For detailed instructions on how to grant Service Account
                access, please see Google’s official documentation
                <a
                  href="https://cloud.google.com/iam/docs/manage-access-service-accounts"
                  target="_blank"
                  class="underline"
                  >here</a
                >
              </b-card-text>
              <b-button
                class="float-left"
                variant="primary"
                @click="onClickBack"
                >Back</b-button
              >
              <b-button
                class="float-right ml-1"
                variant="primary"
                @click="onClickNext"
                >Next</b-button
              >
            </b-card>

            <b-card v-if="current_step == 3" class="card-style">
              <b-card-text class="height-secutity-scan">
                <div
                  class="d-flex justify-content-center"
                  v-if="
                    !projectPermissions &&
                    !projectPermissionsError &&
                    verifyLoading
                  "
                >
                  <span>Verifying service account permissions…</span>
                </div>

                <div
                  class="d-flex justify-content-center"
                  v-if="
                    !projectPermissions &&
                    projectPermissionsError &&
                    !verifyLoading
                  "
                >
                  <span>
                    Failed to validate permissions for the service account.
                    Please check the permissions for the service account and try
                    again. If you need detailed instructions please click
                    <a
                      href="https://cloud.google.com/iam/docs/manage-access-service-accounts"
                      target="_blank"
                      class="underline"
                      >here</a
                    >
                    <br /><br />
                    Once the permissions have been updated, please click the
                    <b>Retry</b> button below to validate.
                  </span>
                </div>

                <div
                  v-if="
                    projectPermissions &&
                    !projectPermissionsError &&
                    !verifyLoading
                  "
                >
                  <b-row>
                    <b-col class="d-flex justify-content-center">
                      Successfully validated permissions for the service
                      account.
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row class="d-flex justify-content-center mt-1">
                    <b-col cols="12" lg="5" md="5">
                      <div
                        v-for="(roleValue, roleKey) in projectRolePermissions"
                        class="d-flex justify-content-start"
                      >
                        <b-row>
                          <b-col cols="12">
                            <feather-icon
                              v-if="roleValue"
                              icon="CheckCircleIcon"
                              size="16"
                              class="feather-success mr-1"
                            />
                            <feather-icon
                              v-if="!roleValue"
                              icon="XIcon"
                              size="16"
                              class="feather-danger mr-1"
                            />
                            {{ roleKey }}
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                  <span
                    class="d-flex justify-content-center mt-2 feather-danger"
                    >{{ projectPermissionsError }}</span
                  >
                </div>

                <div class="d-flex justify-content-center">
                  <b-img
                    src="../../../../public/loading.svg"
                    height="22px"
                    width="50px"
                    alt="loading"
                    v-if="verifyLoading && theme == 'light'"
                  />
                  <b-img
                    src="../../../../public/darkLoading.svg"
                    height="22px"
                    width="50px"
                    alt="darkLoading"
                    v-if="verifyLoading && theme == 'dark'"
                  />
                </div>
              </b-card-text>
              <b-button
                class="float-left"
                variant="primary"
                @click="onClickBack"
                >Back</b-button
              >
              <b-button
                class="float-right"
                variant="primary"
                @click="generateReport"
                v-if="projectPermissions && !projectPermissionsError"
                ><b-spinner
                  small
                  label="Text Centered"
                  v-if="isLoading"
                ></b-spinner
                ><span> Initiate Scan</span></b-button
              >
              <b-button
                class="float-right"
                variant="primary"
                @click="verifyPermissions"
                v-if="projectPermissionsError && !projectPermissions"
                ><b-spinner
                  small
                  label="Text Centered"
                  v-if="verifyLoading"
                ></b-spinner
                ><span> Retry </span></b-button
              >
            </b-card>
          </div>
        </b-modal>
      </b-row>

      <b-table
        id="scoutsuiteTable"
        :items="tableItems"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
        responsive
        class="mt-2 mb-0 position-relative"
      >
        <template #cell(organization_name)="data">
          {{ data.item.company_name }}
        </template>

        <template #cell(date)="data">
          {{ data.item.created_at.split("T")[0] }}
        </template>

        <template #cell(time)="data">
          {{ data.item.created_at.split("T")[1].split(".")[0] }}
        </template>

        <template #cell(statistics)="data">
          <span class="feather-success" title="Good">{{
            data.item.good_stats
          }}</span>
          /
          <span class="feather-warning" title="Warning">{{
            data.item.warning_stats
          }}</span>
          /
          <span class="feather-danger" title="Danger">{{
            data.item.danger_stats
          }}</span>
        </template>

        <template #cell(status)="data">
          <span v-if="data.item.status === 'INQUEUE'" class="feather-info">{{
            data.item.status
          }}</span>
          <span
            v-if="
              data.item.status === 'PERMISSION_DENIED' ||
              data.item.status === 'PROCESSING'
            "
            class="feather-warning"
            >{{ data.item.status }}</span
          >
          <span
            v-if="data.item.status === 'FINISHED'"
            class="feather-success"
            >{{ data.item.status }}</span
          >
          <span v-if="data.item.status === 'FAILED'" class="feather-danger">{{
            data.item.status
          }}</span>
        </template>

        <template #cell(actions)="data">
          <span title="View" v-if="data.item.status == 'FINISHED'">
            <feather-icon
              icon="FileIcon"
              @click="viewReport(data.item)"
              size="20"
              class="action feather-info"
            />
          </span>

          <span
            title="Retry"
            v-if="
              !data.item.retryLoading &&
              (data.item.status == 'FAILED' ||
                data.item.status == 'PERMISSION_DENIED')
            "
          >
            <b-img
              src="../../../../public/loading.png"
              height="22px"
              width="22px"
              alt="loadingIcon"
              @click="retryReport(data.item)"
              class="action"
              v-if="theme == 'light'"
            />
            <b-img
              src="../../../../public/darkLoading.png"
              height="22px"
              width="22px"
              alt="darkLoading"
              @click="retryReport(data.item)"
              class="action"
              v-if="theme == 'dark'"
            />
          </span>

          <span v-if="data.item.retryLoading">
            <b-img
              src="../../../../public/loading.svg"
              height="22px"
              width="50px"
              alt="loading"
              v-if="theme == 'light'"
            />
            <b-img
              src="../../../../public/darkLoading.svg"
              height="22px"
              width="50px"
              alt="darkLoading"
              v-if="theme == 'dark'"
            />
          </span>

          <span
            title="Log File"
            v-if="
              data.item.status !== 'INQUEUE' &&
              data.item.status !== 'PROCESSING'
            "
          >
            <feather-icon
              icon="FileTextIcon"
              @click="showLogFile(data.item)"
              size="20"
              class="action feather-warning"
            />
          </span>
          <span
            title="Delete"
            v-if="
              data.item.status !== 'INQUEUE' &&
              data.item.status !== 'PROCESSING'
            "
          >
            <feather-icon
              icon="TrashIcon"
              @click="showModal(data.item)"
              size="20"
              class="action feather-danger"
            />
          </span>
        </template>
      </b-table>

      <b-modal
        ref="log-file"
        no-close-on-backdrop
        hide-footer
        title="Log Information"
        size="xl"
        scrollable
      >
        <div class="height-log-file" v-if="logFile">
          <loading
            :active.sync="load"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <div v-if="logs">
            <div v-for="(value, key) in logs">
              {{ value }}
              <hr />
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="my-modal"
        no-close-on-backdrop
        hide-footer
        title="Confirmation"
      >
        <div class="d-block text-center" v-if="activeItem">
          <h4 class="mb-0">
            Are you sure you want to delete {{ activeItem.display_name }}
          </h4>
        </div>
        <div class="d-flex justify-content-end" v-if="activeItem">
          <b-button
            class="mt-1 mr-1"
            variant="primary"
            @click="
              (e) => {
                e.stopPropagation();
                deleteReport(activeItem);
                hideModal();
              }
            "
            >Ok</b-button
          >
          <b-button class="mt-1" @click="hideModal">Cancel</b-button>
        </div>
      </b-modal>

      <b-card class="border-top border-bottom">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card>

      <div>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start"
          >
            <span class="text-muted">
              All Security scans performed use CIS Google
              <b-link
                class="ml-25"
                href="https://cloud.google.com/security/compliance/cis"
                target="_blank"
              >
                Cloud Platform Foundations version 1.1.0</b-link
              >
              as the benchmark.
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BImg,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
  BModal,
  BFormSelect,
  BProgress,
  BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { ref } from "@vue/composition-api";
import store from "@/store";
import { io } from "socket.io-client";
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BPagination,
    BFormCheckbox,
    vSelect,
    BModal,
    BFormSelect,
    BProgress,
    BCardText,
    Loading,
  },
  beforeMount() {
    this.theme = store.state.appConfig.layout.skin;
    if (this.userData.role_name == "GLOBAL_ADMIN") {
      this.tableColumns.unshift({ key: "organization_name", sortable: true });
    }
    this.getStatisticsReport();
    this.getOrganization();
    this.getProjectListing();
  },
  computed: {
    rows() {
      return this.totalScoutsuite;
    },
    progress: function () {
      return Math.round(100 / 3) * this.current_step;
    },
  },
  data() {
    return {
      selectedOrganization: null,
      organizationOptions: [],
      projectOptions: [],
      projectLoading: false,
      selectedCurrency: null,
      currencyOptions: [],
      tableColumns: [
        { key: "project_name", sortable: true },
        { key: "date", sortable: true },
        { key: "time", sortable: true },
        { key: "statistics" },
        { key: "status", sortable: true },
        { key: "actions", tdClass: "action-class" },
      ],
      activeItem: null,
      logFile: null,
      addPermissions: null,
      logs: null,
      load: false,
      retryLoading: false,
      retryDataId: null,
      current_step: 1,
      securityAnalysisEmailId: process.env.VUE_APP_SECURITY_ANALYSIS_EMAIL_ID,
      showStepDetails: false,
      noOptionMessage: "Sorry, data is loading",
      theme: "",
    };
  },
  created() {
    if (
      this.userData.default_region_id == "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
    ) {
      const regionCurrencyData = JSON.parse(
        localStorage.getItem("regionCurrencyData")
      );
      for (const [key, value] of Object.entries(
        regionCurrencyData.regions_details
      )) {
        this.currencyOptions = value.currencies;
      }
    }
    this.selectedCurrency = this.userData.deafult_currency_code;
    store.commit("app/CURRENCY_TYPE", this.userData.deafult_currency_code);
    this.socketNotification();
  },
  watch: {
    selectedCurrency(newValue) {
      if (newValue) {
        store.commit("app/CURRENCY_TYPE", newValue);
      }
    },
    isSortDirDesc() {
      this.getProjectListing();
    },
    "$store.state.appConfig.layout.skin": {
      handler() {
        this.theme = localStorage.getItem("vuexy-skin");
      },
      immediate: true,
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.projectPermissions = false;
        this.selectedOrganization = null;
        this.selectedProject = "";
        this.currentPage = 1;
        this.selectedCurrency = this.userData.deafult_currency_code;
        this.getStatisticsReport();
        this.getOrganization();
        this.getProjectListing();
        if (
          this.userData.role_name !== "GLOBAL_ADMIN" &&
          this.userData.role_name !== "ACCOUNT_MANAGER"
        ) {
          this.getProject();
        }
        if (
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
        ) {
          const regionCurrencyData = JSON.parse(
            localStorage.getItem("regionCurrencyData")
          );
          for (const [key, value] of Object.entries(
            regionCurrencyData.regions_details
          )) {
            this.currencyOptions = value.currencies;
          }
        }
      },

      immediate: false,
    },
    currentPage: function () {
      this.getProjectListing();
    },
  },
  methods: {
    renderedUrl() {
      this.$router.push({ name: "apps-support-cases-open-ticket" });
    },
    onClickNext() {
      if (this.current_step == 2) {
        this.verifyPermissions();
      }
      this.current_step++;
    },
    onClickBack() {
      if (this.current_step == 3) {
        this.projectRolePermissions = [];
        this.projectPermissionsError = "";
      }
      this.current_step--;
    },
    viewReport(item) {
      if (item.status == "FINISHED") {
        this.$router.push("/apps/security-scan/" + item.id);
        const securityanalysisData = {
          securityanalysisProjectId: item.id,
          securityanalysisProjectName: item.project_name,
        };
        localStorage.setItem(
          "securityanalysisData",
          JSON.stringify(securityanalysisData)
        );
      }
    },
    addPermission() {
      if (
        this.userData.role_name == "GLOBAL_ADMIN" ||
        this.userData.role_name == "ACCOUNT_MANAGER"
      ) {
        this.projectOptions = [];
      } else {
        this.getProject();
      }
      this.projectPermissions = false;
      this.projectPermissionsError = "";
      this.projectRolePermissions = [];
      this.projectOptions = [];
      this.showStepDetails = false;
      this.selectedOrganization = null;
      this.selectedProject = "";
      this.current_step = 1;
      this.$refs["add-permissions"].show();
    },
    hidePermissionsModal() {
      this.$refs["add-permissions"].hide();
    },
    showLogFile(item) {
      this.logFile = item;
      this.$refs["log-file"].show();
      this.logs = null;

      if (
        this.userData.role_name == "GLOBAL_ADMIN" &&
        item.status !== "PERMISSION_DENIED"
      ) {
        this.load = true;
        axios
          .get(`/security_analysis/${item.id}/logs`)
          .then((response) => {
            this.load = false;
            this.logs = response.data.logs;
          })
          .catch(() => {
            this.load = false;
          });
      } else {
        let data = [];
        data.push(item.message);
        this.logs = data;
      }
    },
    retryReport(item) {
      item.retryLoading = true;
      axios
        .post(
          `/security_analysis/${item.id}?region_id=${this.userData.default_region_id}`
        )
        .then((response) => {
          if (response) {
            item.retryLoading = false;
          }
        })
        .catch(() => {
          item.retryLoading = false;
        });
    },
    showModal(item) {
      this.activeItem = item;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    socketNotification() {
      let socket = this.$socket;
      if (socket == undefined) {
        const socket = io(process.env.VUE_APP_NOTIFICATION_URL, {
          path: "/socketio/open_connection",
          query: {
            auth_token: JSON.parse(localStorage.getItem("accessToken")),
          },
          extraHeaders: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
        socket.on("connect", () => {
          Vue.prototype.$socket = socket;
        });
        socket.on("message", (data) => {
          if (data.target_module == "SECURITY_ANALYSIS") {
            this.getProjectListingForScoket();
            this.getStatisticsReport();
          }
        });
      } else {
        socket.on("message", (data) => {
          if (data.target_module == "SECURITY_ANALYSIS") {
            this.getProjectListingForScoket();
            this.getStatisticsReport();
          }
        });
      }
    },
    generateReport() {
      this.isLoading = true;
      let jsonObj;
      if (
        this.userData.role_name == "GLOBAL_ADMIN" ||
        this.userData.role_name == "ACCOUNT_MANAGER"
      ) {
        jsonObj = {
          company_id: this.selectedOrganization.id,
          company_name: this.selectedOrganization.name,
          cloud_customer_id: this.selectedOrganization.cloud_customer_id,
          project_name: this.selectedProject.split("(")[0],
          project_id: this.selectedProject.split("(")[1].split(")")[0],
        };
      } else {
        jsonObj = {
          company_id: this.userData.company_id,
          company_name: this.userData.company_name,
          cloud_customer_id: this.userData.cloud_customer_id,
          project_name: this.selectedProject.split("(")[0],
          project_id: this.selectedProject.split("(")[1].split(")")[0],
        };
      }

      axios
        .post(`/security_analysis`, jsonObj, {
          params: {
            region_id: this.userData.default_region_id,
          },
        })
        .then((response) => {
          if (response) {
            this.isLoading = false;
            this.selectedOrganization = null;
            this.selectedProject = "";
            this.hidePermissionsModal();
            this.getProjectListing();
            this.getStatisticsReport();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    selectBillingIdFromOrganization() {
      this.selectedProject = "";
      this.getProject();
    },
    getOrganization() {
      if (this.userData.role_name == "GLOBAL_ADMIN") {
        axios
          .get(`/organizations/region/${this.userData.default_region_id}`)
          .then((response) => {
            this.organizationOptions = response.data.items;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.userData.role_name == "ACCOUNT_MANAGER") {
        const paramObj = {
          region_id: this.userData.default_region_id,
        };

        axios
          .get(`/organizations/account_manager/${this.userData.id}`, {
            params: paramObj,
          })
          .then((response) => {
            this.organizationOptions = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getProject() {
      this.noOptionMessage = "Sorry, data is loading";
      this.projectOptions = [];
      let paramObj = {};
      this.projectLoading = true;

      if (this.selectedOrganization !== null) {
        paramObj.selected_company_id = this.selectedOrganization.id;
      }

      axios
        .get(
          `/gcp-spend/options/security_analysis/${this.userData.default_region_id}`,
          {
            params: paramObj,
          }
        )
        .then((response) => {
          let projects = [];
          let projectData = [];
          this.projectLoading = false;
          projects = response.data.project_data;

          if (projects.length == 0) {
            this.noOptionMessage = "Sorry, data is not avaialble";
          }

          projects.forEach((element) => {
            if (element.project_id !== null) {
              projectData.push(
                element.project_name + " (" + element.project_id + ")"
              );
            }
          });
          this.projectOptions = projectData;
        })
        .catch((err) => {
          this.projectLoading = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching projects",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "",
            },
          });
        });
    },
  },
  setup(props, { emit }) {
    let userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useToast();
    let verifyLoading = ref(false);
    let isLoading = ref(false);
    let tableItems = ref([]);
    let currentPage = ref(1);
    let perPage = ref(10);
    let totalScoutsuite = ref(0);
    let selectedProject = ref("");
    let projectPermissions = ref(false);
    let projectRolePermissions = ref([]);
    let projectPermissionsError = ref("");
    let sortBy = ref("date");
    let isSortDirDesc = ref(true);
    let statisticsReportData = ref([]);

    const getStatisticsReport = () => {
      userData = JSON.parse(localStorage.getItem("userData"));

      const paramObj = {
        region_id: userData.default_region_id,
      };

      axios
        .get(`/security_analysis/statistics/data`, {
          params: paramObj,
        })
        .then((response) => {
          statisticsReportData.value = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const verifyPermissions = () => {
      verifyLoading.value = true;
      const project_id = selectedProject.value.split("(")[1].split(")")[0];
      projectRolePermissions.value = [];
      projectPermissionsError.value = "";
      projectPermissions.value = false;

      axios
        .get(`/security_analysis/project_permissions/${project_id}`, {
          params: {
            region_id: userData.default_region_id,
          },
        })
        .then((response) => {
          if (response.data.project_permissions) {
            projectPermissions.value = true;
            projectRolePermissions.value = response.data.permissions;
          } else {
            projectPermissionsError.value = response.data.error;
            projectRolePermissions.value = response.data.permissions;
          }
          verifyLoading.value = false;
        })
        .catch((err) => {
          verifyLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data.error.message,
            },
          });
        });
    };

    const getProjectListing = () => {
      userData = JSON.parse(localStorage.getItem("userData"));
      store.commit("appConfig/SPINNER_CONFIG", true);
      let pramsObj;
      pramsObj = {
        page: currentPage.value,
        per_page: perPage.value,
        sort_by: sortBy.value,
        sort_order: isSortDirDesc.value == true ? "desc" : "asc",
        region_id: userData.default_region_id,
      };

      axios
        .get(`/security_analysis`, {
          params: pramsObj,
        })
        .then((response) => {
          tableItems.value = response.data.reports;
          for (const [key, value] of Object.entries(tableItems.value)) {
            value.retryLoading = false;
          }
          totalScoutsuite.value = response.data.total;
          store.commit("appConfig/SPINNER_CONFIG", false);
          isLoading.value = false;
        })
        .catch((err) => {
          store.commit("appConfig/SPINNER_CONFIG", false);
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data.error.message,
            },
          });
        });
    };

    const getProjectListingForScoket = () => {
      userData = JSON.parse(localStorage.getItem("userData"));
      let pramsObj;
      pramsObj = {
        page: currentPage.value,
        per_page: perPage.value,
        sort_by: sortBy.value,
        sort_order: isSortDirDesc.value == true ? "desc" : "asc",
        region_id: userData.default_region_id,
      };

      axios
        .get(`/security_analysis`, {
          params: pramsObj,
        })
        .then((response) => {
          tableItems.value = response.data.reports;
          for (const [key, value] of Object.entries(tableItems.value)) {
            value.retryLoading = false;
          }
          totalScoutsuite.value = response.data.total;
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data.error.message,
            },
          });
        });
    };

    const deleteReport = (item) => {
      axios
        .delete(`/security_analysis/${item.id}`, {
          params: {
            region_id: userData.default_region_id,
          },
        })
        .then((response) => {
          if (response) {
            getProjectListing();
            getStatisticsReport();
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Report deleted successfully`,
              },
            });
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Report could not deleted`,
            },
          });
        });
    };

    return {
      isLoading,
      sortBy,
      isSortDirDesc,
      verifyLoading,
      projectPermissions,
      projectRolePermissions,
      projectPermissionsError,
      perPage,
      currentPage,
      totalScoutsuite,
      tableItems,
      userData,
      selectedProject,
      statisticsReportData,
      getStatisticsReport,
      verifyPermissions,
      getProjectListing,
      deleteReport,
      getProjectListingForScoket,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.open-ticket {
  border: 1px solid $partner-border-color;
  border-radius: 0.375rem;
}
.scoutsuiteTable {
  cursor: pointer;
}
.action {
  cursor: pointer;
  margin: 0.5rem 1rem 0.5rem 0rem;
}
.underline {
  text-decoration: underline;
}
.underline:hover {
  text-decoration: underline;
}
.card-style {
  margin-bottom: 0;
  box-shadow: none;
}
.action-class {
  width: 15%;
}
.loading-project {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.height-secutity-scan {
  min-height: 19.3rem;
}
.height-log-file {
  min-height: 5rem;
}
.loader {
  position: absolute;
  margin-top: 2.7rem;
  margin-left: 33rem;
}
.select-organization {
  .vs__dropdown-menu {
    max-height: 10rem;
  }
}
.select-project {
  .vs__dropdown-menu {
    max-height: 10rem;
  }
}
.number-size {
  font-size: 3rem;
  margin-bottom: 0;
}
.card-style {
  margin-bottom: 0;
  .card-body {
    padding-top: 0.5rem;
    padding-bottom: 0;
  }
}
.main-card {
  .card-body {
    padding: 1rem;
  }
}
</style>
